var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expandHeader pb-12" },
    [
      _c(
        "v-container",
        [
          _c("the-layout-header", {
            attrs: { title: "Get a hardware wallet today!" },
          }),
          _c(
            "v-sheet",
            {
              staticClass: "mx-auto",
              attrs: {
                color: "transparent",
                "max-width": !_vm.$vuetify.breakpoint.smAndDown
                  ? "900px"
                  : "470px",
              },
            },
            [
              _c(
                "v-row",
                _vm._l(_vm.buttons, function (b, key) {
                  return _c(
                    "v-col",
                    { key: key, attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "a",
                        { attrs: { href: b.link, target: "_blank" } },
                        [
                          _c(
                            "mew-button",
                            {
                              staticStyle: { height: "200px" },
                              attrs: {
                                "color-theme": "basic",
                                "btn-style": "light",
                                "has-full-width": "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-5 text-left d-flex align-center justify-space-between",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      b.logoImg
                                        ? _c("v-img", {
                                            staticClass: "mb-3",
                                            attrs: {
                                              "max-height": "30px",
                                              "max-width": "120px",
                                              src: b.logoImg,
                                              alt: "Hardware wallet",
                                            },
                                          })
                                        : _vm._e(),
                                      b.logoText
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center mb-3",
                                              class: !_vm.$vuetify.breakpoint
                                                .smAndDown
                                                ? "mew-subtitle"
                                                : "mew-heading-2",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(b.logoText) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mew-caption text-uppercase font-weight-bold textDark--text text--lighten-1",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(b.priceNote) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "textDark--text d-flex",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mew-caption mr-1 font-weight-black",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(b.currency) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mew-heading-2 text-uppercase font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(b.price) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-3 greenPrimary--text font-weight-medium",
                                        },
                                        [_vm._v(" Learn more > ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "pl-4" },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: b.walletImg,
                                          alt: "Hardware Wallet",
                                          "max-width": "90px",
                                          "max-height": "100px",
                                          contain: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }